const { render, useState } = wp.element;
import { Formik, Form, Field } from 'formik';
import Turnstile, { useTurnstile } from "react-turnstile";
import * as Yup from 'yup';
import _Map from 'lodash/map';
import toast, { Toaster } from 'react-hot-toast';

const ContactFormSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Name Required'),
  email: Yup.string().email('Invalid email').required('Email Required'),
  subject: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Subject Required'),
  captcha: Yup.string().required(),
  message: Yup.string().min(2, 'Too Short!').max(180, 'Too Long!').required('Message Required')
});

const ContactForm = (props) => {
  const [token, setToken] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const turnstile = useTurnstile();

  // If the form is sent, lets show this nice message.
  if (formSent)
    return (
      <div className="row my-3">
        <div className="col-12 text-center">
          <img
            src="https://assets.getasite.co/assets/2022/09/22190405/party-popper.png"
            alt=""
            width={80}
            className={'img-fluid'}
          />
          <h3 className="mb-0">Information Successfully Sent!</h3>
        </div>
      </div>
    );

  return (
    <>
      <Formik
        initialValues={{ name: '', email: '', subject: '', captcha: token, message: '' }}
        validationSchema={ContactFormSchema}
        onSubmit={async (values) => {
          // Set the Loading to true
          setLoading(true);

          // Send the Information to the Backend in order to be send to the owner of the site.
          const response = await fetch('/wp-json/getasite/v1/blocks/contact', {
            method: 'POST',
            mode: 'same-origin',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
          });

          // Check the Success variables coming from the server
          const body = await response.json();

          if (!body?.success) {
            // Set the Loading to False
            setLoading(false);

            // Display Something Off
            toast.error(`Error: ${body.message}`);
            return;
          }

          // Set the Loading to False
          setLoading(false);
          setFormSent(true);
        }}>
        {({ setFieldValue, errors, touched }) => {
          return (
            <Form className={'row my-3'}>
              <div className={`col-12 col-lg-6 mb-3 ${errors.name ? 'has-validation' : ''}`}>
                <Field name={'name'}>
                  {({ field, meta }) => {
                    return (
                      <>
                        <input
                          type="text"
                          name="name"
                          className={`form-control ${
                            meta.touched && meta.error ? 'is-invalid' : ''
                          }`}
                          placeholder={'Name'}
                          required
                          disabled={loading}
                          {...field}
                        />
                        {meta.touched && meta.error && (
                          <div className="invalid-feedback">{meta.error}</div>
                        )}
                      </>
                    );
                  }}
                </Field>
              </div>
              <div className={`col-12 col-lg-6 mb-3 ${errors.email ? 'has-validation' : ''}`}>
                <Field name={'email'}>
                  {({ field, meta }) => {
                    return (
                      <>
                        <input
                          type="text"
                          name="email"
                          className={`form-control ${
                            meta.touched && meta.error ? 'is-invalid' : ''
                          }`}
                          placeholder={'Email Address'}
                          required
                          disabled={loading}
                          {...field}
                        />
                        {meta.touched && meta.error && (
                          <div className="invalid-feedback">{meta.error}</div>
                        )}
                      </>
                    );
                  }}
                </Field>
              </div>
              <div className={`col-12 mb-3 ${errors.subject ? 'has-validation' : ''}`}>
                <Field name={'subject'}>
                  {({ field, meta }) => {
                    return (
                      <>
                        <input
                          type="text"
                          name="subject"
                          className={`form-control ${
                            meta.touched && meta.error ? 'is-invalid' : ''
                          }`}
                          placeholder={'Subject'}
                          required
                          disabled={loading}
                          {...field}
                        />
                        {meta.touched && meta.error && (
                          <div className="invalid-feedback">{meta.error}</div>
                        )}
                      </>
                    );
                  }}
                </Field>
              </div>
              <div className={`col-12 mb-3 ${errors.message ? 'has-validation' : ''}`}>
                <Field name={'message'}>
                  {({ field, meta }) => {
                    return (
                      <>
                        <textarea
                          rows={4}
                          name="message"
                          className={`form-control ${
                            meta.touched && meta.error ? 'is-invalid' : ''
                          }`}
                          placeholder={'Type the Message...'}
                          required
                          disabled={loading}
                          {...field}
                        />
                        {meta.touched && meta.error && (
                          <div className="invalid-feedback">{meta.error}</div>
                        )}
                      </>
                    );
                  }}
                </Field>
              </div>
              <div className={`col-12 mb-3 text-center ${errors.captcha ? 'has-validation' : ''}`}>
                <Field name={'captcha'}>
                  {({ meta }) => {
                    return (
                      <>
                        <Turnstile
                          sitekey="0x4AAAAAAACGeonsQjgA3_Mi"
                          onVerify={(token) => {
                            setToken(token);
                            setFieldValue('captcha', token);
                          }}
                        />
                          {meta.error && (
                          <div className="text-danger">
                            <small>{meta.error}</small>
                          </div>
                        )}
                      </>
                    );
                  }}
                </Field>
              </div>
              <div className={'col-12'}>
                <div className={'d-grid'}>
                  <button
                    type="submit"
                    disabled={loading || !token}
                    className={`btn ${
                      Number(props.hasBg) === 1 ? 'bg-white text-primary' : 'btn-primary text-white'
                    }`}>
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"></span>{' '}
                        Submitting...
                      </>
                    ) : (
                      props.submitText
                    )}
                  </button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Toaster />
    </>
  );
};

if (document.getElementsByTagName('contact-form').length !== 0) {
  _Map(document.getElementsByTagName('contact-form'), (el) => {
    render(<ContactForm submitText={el.dataset?.submitBtnText} hasBg={el.dataset?.hasBg} />, el);
  });
}
